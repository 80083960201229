.app-header {
  background: #fff !important;
  padding-left: 20px;
  border-bottom: 2px solid #ffc400;
}

.header-logo-container {
  margin-left: 10px;
  display: flex;

  a {
    line-height: normal;
  }
}

.header-logo {
  max-height: 28px;
}

.header-title {
  margin-left: 10px;

  span {
    color: #206986;
    font-size: 1.5em;
    font-weight: 500;
  }
}
