.featured-card-container {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  .featured-image {
    flex-grow: 1;
    padding: 20px;
    width: 100%;

    &.small { max-width: 130px; }
    &.default { max-width: 180px; }
    &.large { max-width: 390px; }

    & > a, & > span {
      display: inline-block;
      width: 100%;

      & > * { width: 100%; }
    }
  }

  .featured-title {
    font-size: 1.4em;
    font-weight: 600;
  }

  .featured-description{
    font-size: .9em;
    color: #5c5c5c;
  }
}
